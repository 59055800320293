import React from 'react';

export default class Footer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    stop() {
    }

    render() {
        return (
            <footer>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="block">
                                <p>Copyright &copy; <a href="http://www.Themefisher.com">Themefisher</a>| All right
                                    reserved.</p>
                                <p>Powered by <a href={"https://www.coindesk.com/price/bitcoin"}>CoinDesk</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>

        );
    }
}


import React from 'react';
import Message from "./Message";
import Header from "./Header";
import Hero from "./Hero";
import About from "./About";
import Footer from "./Footer";

export default class App extends React.Component {

    constructor(props) {

        super(props);
        this.state = {showModal: false}

    }

    handleCloseModal() {
        this.setState({showModal: false});
    }

    render() {
        return (
            <div className="App content-wrap">
                <Header></Header>
                <Hero></Hero>
                <About></About>
                <Message></Message>
                <Footer></Footer>

                {/*<footer>*/}
                {/*    Copyright © 2020 Bitcoin Pizza Day - All Rights Reserved.<br/>*/}
                {/*        Powered by <a href={"https://www.coindesk.com/price/bitcoin"} >CoinDesk</a>*/}
                {/*</footer>*/}
            </div>
        );
    }


}


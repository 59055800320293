import React from 'react';

export default class Countdown extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            days: 0,
            hours: 0,
            min: 0,
            sec: 0,
        }
    }

    componentDidMount() {
        this.interval = setInterval(() => {
            //console.log(new Date(this.props.date));
            const date = this.calculateCountdown(this.props.date);
            date ? this.setState(date) : this.stop();
        }, 1000);
    }


    componentWillUnmount() {
        this.stop();
    }

    calculateCountdown(endDate) {
        let diff = (Date.parse(new Date(endDate)) - Date.parse(new Date())) / 1000;

        // clear countdown when date is reached
        if (diff <= 0) return false;

        const timeLeft = {
            years: 0,
            days: 0,
            hours: 0,
            min: 0,
            sec: 0,
            millisec: 0,
        };

        // calculate time difference between now and expected date
        if (diff >= (365.25 * 86400)) { // 365.25 * 24 * 60 * 60
            timeLeft.years = Math.floor(diff / (365.25 * 86400));
            diff -= timeLeft.years * 365.25 * 86400;
        }
        if (diff >= 86400) { // 24 * 60 * 60
            timeLeft.days = Math.floor(diff / 86400);
            diff -= timeLeft.days * 86400;
        }
        if (diff >= 3600) { // 60 * 60
            timeLeft.hours = Math.floor(diff / 3600);
            diff -= timeLeft.hours * 3600;
        }
        if (diff >= 60) {
            timeLeft.min = Math.floor(diff / 60);
            diff -= timeLeft.min * 60;
        }
        timeLeft.sec = diff;

        return timeLeft;
    }

    stop() {
        clearInterval(this.interval);
    }

    getDigit(nth, number) {
        return Math.floor(number / Math.pow(10, nth - 1)) % 10
    }

    render() {
        const countDown = this.state;
        return (
            <div className="block">
                <div className="counter text-center">
                    <ul id="countdown_dashboard">
                        <li>
                            <div className="dash days_dash">
                                <div className="digit">{this.getDigit(3, countDown.days)}</div>
                                <div className="digit">{this.getDigit(2, countDown.days)}</div>
                                <div className="digit">{this.getDigit(1, countDown.days)}</div>
                                <span className="dash_title">Days</span>
                            </div>
                        </li>
                        <li>
                            <div className="dash hours_dash">
                                <div className="digit">{this.getDigit(2, countDown.hours)}</div>
                                <div className="digit">{this.getDigit(1, countDown.hours)}</div>
                                <span className="dash_title">Hours</span>
                            </div>
                        </li>
                        <li>
                            <div className="dash minutes_dash">
                                <div className="digit">{this.getDigit(2, countDown.min)}</div>
                                <div className="digit">{this.getDigit(1, countDown.min)}</div>
                                <span className="dash_title">Minutes</span>
                            </div>
                        </li>
                        <li>
                            <div className="dash seconds_dash">
                                <div className="digit">{this.getDigit(2 , countDown.sec)}</div>
                                <div className="digit">{this.getDigit(1, countDown.sec)}</div>
                                <span className="dash_title">Seconds</span>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>

        );
    }

}


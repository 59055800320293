import React from 'react';
import numToWords from "num-to-words/src";

export default class BitcoinPrice extends React.Component {

    constructor(props) {
        super(props);
        this.state = {bpi: {}, currency: 'USD'}

    }

    componentWillMount() {
        fetch('https://api.coindesk.com/v1/bpi/currentprice.json')
            .then(res => res.json())
            .then(data =>
                this.setState({bpi: data.bpi}))

    }

    handleSelect(e) {
        this.setState({currency: e.target.value})
    }

    getCurrencyDropdown() {
        return <select className="custom-select-sm currency-select"
                       placeholder='Select your currency' onChange={(e) => this.handleSelect(e)}
                       name="currency" value={this.state.currency}>
            {
                Object.keys(this.state.bpi).map((element, index, array) => {
                    return <option key={`currency${element}${index}`}>
                        {element}
                    </option>
                })
            }
        </select>
    }

    render() {
        if (Object.keys(this.state.bpi).length === 0) {
            return (
                <p className="wow fadeInDown" data-wow-delay="0.5s" data-wow-duration=".5s">
                    State Undefined
                </p>
            )
        }

        // const currencySymbols = {'USD': '$', 'EUR': '€', 'GBP': '£'}
        const bpiForCurrency = this.state.bpi[this.state.currency]
        let formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: this.state.currency,
        });
        let pizzaPrice = Math.floor(bpiForCurrency.rate_float) * 10000
        return (
            <div>
                <p className="wow fadeInDown" data-wow-delay="0.5s" data-wow-duration=".5s">
                    To celebrate the most expensive pizza that mankind has known till date.<br/>
                    A {"\uD83C\uDF55"} that is
                    worth
                    <span
                        className="pizza-price">{" " + formatter.format(pizzaPrice) + " "}</span>
                    {" ( " + numToWords(pizzaPrice) + " )"}
                    {this.getCurrencyDropdown()} as of
                    today
                </p>

            </div>
        );
    }
}

import React from 'react';
import ReactModal from "react-modal";
import * as emailjs from 'emailjs-com'

export default class Message extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            phone: '',
            message: '',
            sendStatus: 0
        }

    }

    handleSubmit(e) {
        e.preventDefault()
        const {name, email, phone, message} = this.state
        let templateParams = {
            from_name: name,
            to_name: 'contact@happybitcoinpizzaday.com',
            from_email: email,
            phone: phone,
            message_html: message,
        }
        let that = this
        emailjs.send(
            'service_ieqpg3h',
            'template_jxrxmuv',
            templateParams,
            'user_cPY3Sal3yZYzEYiTsEu9Z'
        )
            .then(function (response) {
                that.state.sendStatus = 1
                console.log('SUCCESS!', response.status, response.text);
            }, function (err) {
                that.state.sendStatus = -1
                console.log('FAILED...', err);
            });
        this.resetForm()
    }

    resetForm() {
        this.setState({
            name: '',
            email: '',
            phone: '',
            message: '',
        })
    }

    handleChange(e) {
        let target = e.target;
        let name = target.name;
        let value = target.value;

        this.setState({
            [name]: value
        });
    }


    componentDidMount() {
        ReactModal.setAppElement('body');
    }


    handleCloseModal() {
        this.props.closeModal()
    }


    render() {
        return (
            <section id="contact" className="section contact">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-12">
                            <div className="block">
                                <div className="heading wow fadeInUp">
                                    <h2>Get In Touch</h2>
                                    <p>Please fill in the form if you have any queries</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-12 col-md-6 offset-md-3 wow fadeInUp"
                             data-wow-delay="0.3s">
                            <div className="form-group">
                                <form action="#" method="post" id="contact-form">
                                    <div className="input-field">
                                        <input type="text" className="form-control" placeholder={"Your Name"}
                                               name="name"
                                               value={this.state.name} onChange={(e) => this.handleChange(e)}/>
                                    </div>
                                    <div className="input-field">
                                        <input type="email" className="form-control" placeholder={"Email Address"}
                                               name="email" value={this.state.email}
                                               onChange={(e) => this.handleChange(e)}/>
                                    </div>
                                    <div className="input-field">
                                        <input type="number" className="form-control" placeholder={"Phone"}
                                               name="phone" value={this.state.phone}
                                               onChange={(e) => this.handleChange(e)}/>
                                    </div>
                                    <div className="input-field">
                                        <textarea className="form-control" placeholder={"How can we help?"} rows="3"
                                                  name="message" value={this.state.message}
                                                  onChange={(e) => this.handleChange(e)}/>
                                    </div>
                                    <button className="btn btn-send" type="submit"
                                            onClick={(e) => this.handleSubmit(e)}
                                            disabled={this.state.email === ""}>Send me
                                    </button>
                                </form>
                                {this.state.sendStatus === 1 &&
                                <div id="success">
                                    <p>Your Message was sent successfully</p>
                                </div>
                                }
                                {this.state.sendStatus === -1 && <div id="error">
                                    <p>Your Message was not sent successfully</p>
                                </div>}

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

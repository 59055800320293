import React from 'react';
import Countdown from "./Countdown";
import BitcoinPrice from "./BitcoinPrice";

export default class Hero extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    stop() {
    }

    render() {
        const currentDate = new Date();
        const year = (currentDate.getMonth() === 11 && currentDate.getDate() > 23) ? currentDate.getFullYear() + 1 : currentDate.getFullYear();
        return (
            <section className="hero-area bg-1" id="hero">
                <div className="container">
                    <div className="row">
                        <div className="col-md-7">
                            <div className="block">
                                <h1 className="wow fadeInDown" data-wow-delay="0.3s" data-wow-duration=".2s">Countdown
                                    to next Bitcon Pizza Day</h1>
                               <BitcoinPrice></BitcoinPrice>
                                <div className="wow fadeInDown" data-wow-delay="0.7s" data-wow-duration=".7s">
                                    <a className="btn btn-home" href="#about" role="button">Learn more</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-5 wow zoomIn">
                            <Countdown date={`May 22 ${year + 1} 00:00:00 PST`}></Countdown>
                        </div>
                    </div>
                </div>
            </section>

        );
    }
}

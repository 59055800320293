import React from 'react';

export default class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    stop() {
    }

    render() {
        return (
            <div className="container">
                <nav className="navbar navbar-fixed-top  navigation " id="top-nav">
                    <a className="navbar-brand" href="#about">
                        {/*<img src="images/logo.png" alt=""/>*/}
                        <a className="logo btn" href="#about">Bitcoin Pizza Day</a>
                    </a>

                    <button className="navbar-toggler hidden-lg-up float-lg-right" type="button" data-toggle="collapse"
                            data-target="#navbarResponsive">
                        <i className="tf-ion-android-menu"></i>
                    </button>
                    <div className="collapse navbar-toggleable-md" id="navbarResponsive">
                        <ul className="nav navbar-nav menu float-lg-right" id="top-nav">
                            <li className="nav-item active">
                                <a className="nav-link" href="#hero">HOME</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#about">ABOUT</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#contact">CONTACT</a>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>

        );
    }
}

import React from 'react';
import offer from './images/about/1.jpg'

export default class About extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    stop() {
    }

    render() {
        return (
            <section className="section about bg-gray" id="about">
                <div className="container">
                    <div className="row">
                        <div className="col-md-7 col-sm-12 wow fadeInLeft">
                            <div className="content">
                                <div className="sub-heading">
                                    <h3>What is Bitcoin Pizza Day?</h3>
                                </div>
                                <p>On May 22, 2010, Laszlo Hanyecz agreed to pay 10,000
                                    Bitcoins for two delivered Papa John's pizzas. Organized on bitcointalk forum, the
                                    Florida man reached out for help. "I'll pay 10,000 bitcoins for a couple of pizzas..
                                    like maybe 2 large ones so I have some left over for the next day," Hanyecz
                                    wrote.</p>
                                <p>
                                    A British man took up Hanyecz's offer and bought the two pizzas for him in exchange
                                    for the 10,000 Bitcoins. Even then the recipient of the Bitcoins got himself a
                                    bargain, paying $25 for the pizzas, while 10,000 Bitcoins were worth around $41 at
                                    the time. <a
                                    href="https://www.investopedia.com/news/bitcoin-pizza-day-celebrating-20-million-pizza-order/">Read
                                    more</a>
                                </p>

                            </div>
                        </div>
                        <div className="col-md-5 col-sm-12 wow fadeInLeft" data-wow-delay="0.3s">
                            <div className="about-slider">
                                <img src={offer} onClick="window.open(this.src)" role="button" tabIndex="0" alt="slider"></img>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        );
    }
}
